import { IUpdateBankingInformation } from "utils/interfaces";
import { talentoApi } from "./index";
import { IUploadDocumentFormSchemaColombia } from 'components/organisms/NewUploadDocumentsColombia';

const bankingInfoExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    updateBankingInformation: builder.mutation<
      { message: string; status?: number },
      IUpdateBankingInformation
    >({
      query: (body) => ({
        url: `/hiring/candidates/updateUserBankInformation`,
        method: 'POST',
        body,
      }),
    }),


    saveBankInformation: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
        bankInformation: Exclude<
          IUploadDocumentFormSchemaColombia['bankInformation'],
          'hasBank' | 'wantsBancolombiaAcc'
        >;
      } & Pick<
        IUploadDocumentFormSchemaColombia,
        'hasBank' | 'wantsBancolombiaAcc'
      >
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserBankInformation',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useUpdateBankingInformationMutation,
  useSaveBankInformationMutation,

} = bankingInfoExtensionApi;