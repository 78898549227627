import { ReactNode } from 'react';
import { AButton, AImage } from 'components/atoms';
import { Modal } from 'components/organisms';
import { xCloseIcon } from 'utils/assets';
import './InfoModal.scss';

interface IProps {
  title?: string;
  text: string | string[] | ReactNode;
  isVisible: boolean;
  onClose?: () => void;
  hideButtons?: true;
  buttonText?: string;
  buttonAction?: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  children?: ReactNode;
  subtitle?: string;
}

export const InfoModal = ({
  title,
  text,
  isVisible,
  onClose,
  hideButtons,
  buttonText,
  buttonAction,
  secondaryButtonText,
  secondaryButtonAction,
  children,
  subtitle
}: IProps) => {
  return (
    <Modal isVisible={isVisible} width='70rem'>
      <div className="infoModal__header">
        <h5 className="infoModal__headerTitle">{title ? title : 'Recuerda'}</h5>
        <div
          className="infoModal__headerCloseContainer"
          onClick={onClose}
          data-testid="closeIconContainer"
        >
          <AImage alt="Cerrar modal" url={xCloseIcon} />
        </div>
      </div>
      <div className="infoModal__main">
        {subtitle && (<h6 className='infoModal__headerSubTitle'>{subtitle}</h6>)}
        {typeof text === 'string' ? (
          <p className="infoModal__text">{text}</p>
        ) : Array.isArray(text) ? (
          <ul>
            {(text as string[]).map((text, index) => (
              <li key={index} className="infoModal__listItem">
                <p
                  className="infoModal__text"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="infoModal__text">{text}</p>
        )}
        {children && (
          <div className="infoModal__childrenContainer">{children}</div>
        )}
        {!hideButtons && (
          <div className="infoModal__buttonsContainer">
            {secondaryButtonText && (
              <AButton
                tag="button"
                type="button"
                className={`infoModal__button infoModal__button--secondary infoModal__button--full button-secondary`}
                title={secondaryButtonText ? secondaryButtonText : 'Continuar'}
                handleClick={secondaryButtonAction}
              />
            )}
            <AButton
              tag="button"
              type="button"
              className={`infoModal__button ${secondaryButtonText ? 'infoModal__button--full' : ''
                } button-primary`}
              title={buttonText ? buttonText : 'Continuar'}
              handleClick={buttonAction}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
