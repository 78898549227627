import { AImage } from "components/atoms";
import { byPragma, pragmaLogoDark} from "utils/assets";
import styles from "./Footer.module.scss";

interface IFooterProps {
  isDark?: boolean
}

const Footer: React.FC<IFooterProps> = ({isDark}) => {
  return (
    <footer className={`${styles['footer']} ${isDark ? styles['footer--dark'] : ""}`} >
      {
        isDark? (
          <div className={styles['footer__large--dark']}>
            <div className={styles['line-container']}>
              <div className={styles['purple-line']}></div>
              <div className={styles['white-line']}></div>
            </div>
          <div>
          </div>
          <AImage
            className=""
            url={pragmaLogoDark}
            alt=""
            dataTestid="desktop-logo"
          />
        </div>
        ) : (
        <div className={styles['footer__large']}>
          <div>
          </div>
          <AImage
            className=""
            url={byPragma}
            alt=""
            dataTestid="desktop-logo"
          />
        </div>
        )
      }

    </footer>
  );
}
 
export default Footer;
