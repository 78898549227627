import { Link, useMatch } from 'react-router-dom';
import { AImage } from 'components/atoms'
import './TabbarItem.components.scss';
import { circle } from 'utils/assets';

interface IProps {
  path: string;
  title: string;
  icon: string;
  isDisabled?: boolean;
  enableText?: boolean;
}

export const TabbarItem = ({ path, title, icon, isDisabled, enableText }: IProps) => {
  const match = useMatch(path);

  return (
    <Link
      to={`/${path}`}
      className={`tabbar-item ${match ? 'tabbar-item--active' : ''} ${isDisabled ? 'tabbar-item--inactive' : ''}`}
    >

      <div className={`tabbar-item__icon--container ${!enableText && 'm-0'}`}>
        <img src={circle} alt="circulo" className='tabbar-item__icon--circle' />
        <AImage
          url={icon}
          alt=''
          className={`tabbar-item__icon ${match && 'tabbar-item__icon--active'}`}
        />
      </div>
      {enableText && <span className="tabbar-item__title">{title}</span>}


    </Link>
  );
};
