import { ISaveDocumentBeneficiaryBody, ISaveDocumentBody } from "utils/interfaces";
import { talentoApi } from "./index";

const filesExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    saveDocument: builder.mutation<{ url: string }, ISaveDocumentBody>({
      query: (body) => ({
        url: `/files/files`,
        method: 'POST',
        body,
      }),
    }),

    saveDocumentBeneficiaryInHiring: builder.mutation<
      { [key: string]: string },
      ISaveDocumentBeneficiaryBody
    >({
      query: (body) => ({
        url: `/files/files/beneficiary`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSaveDocumentMutation,
  useSaveDocumentBeneficiaryInHiringMutation,

} = filesExtensionApi;