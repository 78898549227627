import { IDeleteSavedDocumentBody, IResponse, IResponseSavedDocuments, ISaveDocumentInHiringBody, ISendDocumentsBody } from "utils/interfaces";
import { talentoApi } from "./index";

const documentManagementExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    saveDocumentInHiring: builder.mutation<{}, ISaveDocumentInHiringBody>({
      query: (body) => ({
        url: `/hiring/documents`,
        method: 'POST',
        body,
      }),
    }),

    sendDocumentsInHiring: builder.mutation<IResponse, ISendDocumentsBody>({
      query: ({ email, idVacant, fullName }) => ({
        url: `/hiring/documents/send?email=${email}&idVacant=${idVacant}`,
        method: 'POST',
      }),
    }),

    getSavedDocuments: builder.query<
      IResponseSavedDocuments,
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/documents?email=${email}`,
      }),
      keepUnusedDataFor: 0,
    }),

    deleteSavedDocument: builder.mutation<{}, IDeleteSavedDocumentBody>({
      query: (body) => ({
        url: `/hiring/documents`,
        method: 'DELETE',
        body,
      }),
    }),

    getDownloadPdfUrl: builder.query<{ body: string }, { email: string }>({
      query: ({ email }) => ({
        url: `/hiring/documents/preload?email=${email}`,
      }),
    }),

    declarationDocumentUrl: builder.query<{ url: string }, any>({
      query: () => ({
        url: '/hiring/documents/blueprints?name=Declaracion_jurada_seguro_de_vida&countryIso=PER',
      }),
    }),

    confirmDataResume: builder.mutation<
      { message: string; status?: number },
      { email: string; idVacant: number }
    >({
      query({ email, idVacant }) {
        return {
          url: `/hiring/candidates/confirmDocumentUpload?email=${email}&idVacant=${idVacant}`,
          method: 'PATCH',
        };
      },
    }),
  }),
});

export const {
  useSaveDocumentInHiringMutation,
  useSendDocumentsInHiringMutation,
  useGetSavedDocumentsQuery,
  useDeleteSavedDocumentMutation,
  useGetDownloadPdfUrlQuery,
  useDeclarationDocumentUrlQuery,
  useConfirmDataResumeMutation,

} = documentManagementExtensionApi;