import { talentoApi } from "./index";
import { IWelcomeKitResponse } from "utils/interfaces";
import { kitImplementProps } from "utils/interfaces/forms/chooseKit";

const welcomeKitApiExtension = talentoApi.injectEndpoints({
    endpoints: (builder) => ({
        getKitImplements: builder.query({
            query: () => ({
                url: `/hiring/welcomeKit/kitImplement`,
            }),
            transformResponse: (response: { data: kitImplementProps[] }) => response.data
        }),
        getWelcomeKit: builder.query<{ data: IWelcomeKitResponse }, { email: string }>({
            query: ({ email }) => ({
                url: `/hiring/welcomeKit?email=${email}`,
            }),
        }),
        selectKitImplement: builder.mutation<{}, { email: string; kitImplementId: number }>({
            query: ({ email, kitImplementId }) => ({
                url: `/hiring/welcomeKit/selectedKitImplement?email=${email}&kitImplementId=${kitImplementId}`,
                method: 'POST',
            }),
        }),
        deleteKitImplement: builder.mutation<{}, { email: string; kitImplementId: number }>({
            query: ({ email, kitImplementId }) => ({
                url: `/hiring/welcomeKit/selectedKitImplement?email=${email}&kitImplementId=${kitImplementId}`,
                method: 'DELETE',
            }),
        }),
        updateComputerType: builder.mutation<{}, { computerPragma: boolean; computerOwn: boolean; email: string }>({
            query: ({ computerPragma, computerOwn, email }) => ({
                url: `/hiring/welcomeKit?email=${email}`,
                method: 'PUT',
                body: { computerPragma, computerOwn },
            }),
        }),
        confirmChoseeResume: builder.mutation({
            query: ({ email, idVacant }) => ({
                url: `/hiring/welcomeKit/confirm?email=${email}&idVacant=${idVacant}`,
                method: 'PUT',
                body: { email, idVacant },
            }),
        }),
    }),
});

export const {
    useSelectKitImplementMutation,
    useDeleteKitImplementMutation,
    useUpdateComputerTypeMutation,
    useConfirmChoseeResumeMutation,
    useGetWelcomeKitQuery,
    useGetKitImplementsQuery
} = welcomeKitApiExtension;