import './ACalendarNext.scss';
import { arrowDown, showMoreIcon, chevronUp, chevronUpError } from 'utils/assets';
import { AImage } from 'components/atoms';
import { forwardRef, useEffect, useState } from 'react';
import {
  DayObject,
  MonthObject,
  Views,
  YearObject,
} from 'utils/interfaces/calendar.interface';
import moment from 'moment';
import 'moment/locale/es';
import {
  EmptyString,
  Numbers,
  RHs,
  calendarConstant,
} from 'utils/helpers/dictionaries';

export type ACalendarProps = {
  name?: string;
  birthdateForm: moment.Moment;
  updateBirthdate: (date: string) => void;
  disabled?: boolean;
  setMarginForCalendar: (state: number) => void;
  isOpenCalendar: boolean;
  setIsOpenCalendar: (state: boolean) => void;
  error?: boolean;
  onBlur?: () => void;
};

export const ACalendarNext = forwardRef<any, ACalendarProps>(
  function ACalendarNext(
    {
      name,
      birthdateForm,
      updateBirthdate,
      disabled = false,
      setMarginForCalendar,
      isOpenCalendar,
      setIsOpenCalendar,
      error,
      onBlur,
    },
    ref
  ) {
    const [currencyView, setCurrencyView] = useState<Views>(
      calendarConstant.days
    );
    const [calendar, setCalendar] = useState<DayObject[][]>([]);
    const [selectedDay, setSelectedDay] = useState<moment.Moment | undefined>();
    const [selectedMonth, setSelectedMonth] = useState<number | undefined>();
    const [selectedYear, setSelectedYear] = useState<number | undefined>();
    const [days, setDays] = useState<string[]>([
      calendarConstant.Do,
      calendarConstant.Lu,
      calendarConstant.Ma,
      calendarConstant.Mi,
      calendarConstant.Ju,
      calendarConstant.Vi,
      calendarConstant.Sa,
    ]);
    const [months, setMonths] = useState<MonthObject[]>([]);
    const [years, setYears] = useState<YearObject[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [currentViewYear, setCurrentViewYear] = useState<
      moment.Moment | undefined
    >();
    const [birthdateFormState, setBirthdateFormState] = useState<
      moment.Moment | undefined
    >(undefined);

    const changeView = () => {
      setCurrencyView(calendarConstant.years);
    };

    const isSelectedDay = (date: moment.Moment) => {
      if (selectedDay?.isSame(date)) {
        return Numbers.one;
      }

      return Numbers.zero;
    };

    const isdisableDay = (date: moment.Moment) => {
      const twoYearsFromNow = moment().add(Numbers.two, calendarConstant.years);
      if (date.isAfter(twoYearsFromNow, calendarConstant.day)) {
        return true;
      }

      return false;
    };

    const isSelectedDate = (
      date: moment.Moment,
      type: moment.unitOfTime.StartOf
    ) => {
      if (type === calendarConstant.month) {
        return date.month() === selectedMonth;
      } else {
        return date.year() === selectedYear;
      }
    };

    const isDisabledDate = (
      date: moment.Moment,
      type: moment.unitOfTime.StartOf
    ) => {
      const twoYearsFromNow = moment().add(Numbers.two, calendarConstant.years);

      if (type === calendarConstant.month) {
        if (date.isAfter(twoYearsFromNow, calendarConstant.month)) return true;
        else return false;
      } else {
        if (date.isAfter(twoYearsFromNow, calendarConstant.year)) return true;
        else return false;
      }
    };

    const createCalendar = () => {
      setCalendar([]);

      const startDate = moment(currentDate)
        .startOf(calendarConstant.month)
        .startOf(calendarConstant.week)
        .toDate();

      const endDate = moment(currentDate)
        .endOf(calendarConstant.month)
        .endOf(calendarConstant.week)
        .toDate();

      let date = moment(startDate);

      while (date.isSameOrBefore(endDate)) {
        const week: DayObject[] = [];

        for (let i = Numbers.zero; i < Numbers.seven; i++) {
          week.push({
            disabled: isdisableDay(moment(date)),
            date: moment(date),
            dayOfMonth: moment(date).date(),
            selected: isSelectedDay(moment(date)),
          });

          date.add(Numbers.one, calendarConstant.day);
        }

        setCalendar((state) => [...state, week]);
      }
    };

    const createCalendarMonth = () => {
      setMonths(
        Array.from({ length: Numbers.twelve }, (item, index): MonthObject => {
          let date = moment(currentDate).month(index);
          return {
            date: date,
            selected: isSelectedDate(date, calendarConstant.month),
            disabled: isDisabledDate(date, calendarConstant.month),
          };
        })
      );
    };

    const createCalendarYear = () => {
      const decadeStart =
        Math.floor(currentDate.getFullYear() / Numbers.ten) * Numbers.ten;

      setYears(
        Array.from({ length: Numbers.twelve }, (item, index): YearObject => {
          let date = moment(currentDate).year(decadeStart + index);

          return {
            date: date,
            selected: isSelectedDate(date, calendarConstant.year),
            disabled: isDisabledDate(date, calendarConstant.year),
          };
        })
      );
    };

    const previousDate = (
      amount: number,
      type:
        | calendarConstant.month
        | calendarConstant.year
        | calendarConstant.years
    ) => {
      const newDate = moment(currentDate).subtract(amount, type).toDate();

      // Verifica si el nuevo mes no es anterior a dos años en el pasado
      if (
        moment(newDate).isAfter(
          moment().subtract(Numbers.two, calendarConstant.years)
        )
      ) {
        setCurrentDate(newDate);
      }
    };

    const nextDate = (
      amount: number,
      type:
        | calendarConstant.month
        | calendarConstant.year
        | calendarConstant.years
    ) => {
      const newDate = moment(currentDate).add(amount, type).toDate();
      setCurrentDate(newDate);
    };

    const selectDay = (day: DayObject) => {
      setSelectedDay(day.date);
      updateBirthdate(day.date.format(calendarConstant.dayMonthYear));
      setIsOpenCalendar(false);
    };

    const selectADate = (date: MonthObject | YearObject, view: Views) => {
      setCurrentDate(date.date.toDate());
      setCurrencyView(view);

      if (view === calendarConstant.months) {
        setSelectedYear(date.date.year());
      } else if (view === calendarConstant.days) {
        setSelectedMonth(date.date.month());
        setCurrentViewYear(date.date);
      }
    };

    const formatToPascalCase = (text: string) => {
      return text
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => match.toUpperCase());
    };

    const detectHeightCalendar = () => {
      const heightCalendar = document.getElementById(
        calendarConstant.calendarProfile
      )?.offsetHeight;
      setMarginForCalendar(heightCalendar ? heightCalendar : Numbers.zero);
    };

    useEffect(() => {
      detectHeightCalendar();
    });

    useEffect(() => {
      moment.updateLocale(calendarConstant.es, { week: { dow: Numbers.zero } });
      moment.locale(calendarConstant.es);
    }, []);

    useEffect(() => createCalendar(), []);

    useEffect(() => {
      if (
        birthdateForm.isValid() &&
        birthdateFormState?.date() !== birthdateForm.date()
      ) {
        setBirthdateFormState(birthdateForm);
        setCurrentDate(birthdateForm.toDate());
        setSelectedDay(birthdateForm);
        setSelectedMonth(birthdateForm.month());
        setSelectedYear(birthdateForm.year());
        setCurrentViewYear(birthdateForm);
      } else if (!birthdateForm.isValid()) {
        setSelectedMonth(moment().month());
        setSelectedYear(moment().year());
        setCurrentViewYear(moment());
      }
    }, [birthdateForm]);

    useEffect(() => {
      if (currencyView === calendarConstant.days) createCalendar();
      else if (currencyView === calendarConstant.months) createCalendarMonth();
      else if (currencyView === calendarConstant.years) createCalendarYear();
    }, [currentDate, currencyView]);

    return (
      <div className="a_calendar-wrap" ref={ref} data-testid="calendar-input">
        <div
          className={`a_date ${disabled ? 'a_date--disabled' : ''}`}
          onClick={() => {
            if (!disabled) {
              setIsOpenCalendar(!isOpenCalendar);
              createCalendar();
            }
          }}
        >
          <AImage
            className={`a_date__figure ${
              isOpenCalendar ? 'a_date__figure--open' : EmptyString.emptyString
            } ${
              disabled ? 'a_date__figure--disabled' : EmptyString.emptyString
            }`}
            url={error ? chevronUpError : chevronUp}
            alt={calendarConstant.openCalendary}
          /> 
          <input
            name={name ? name : calendarConstant.calendar}
            type="text"
            placeholder={calendarConstant.calendarPlaceholder}
            value={selectedDay?.format(calendarConstant.dayMonthYear)}
            disabled={disabled}
            className={`a_date__input ${
              disabled ? 'a_date__input--disabled' : EmptyString.emptyString
            } ${error ? 'a_date__input--error' : EmptyString.emptyString}`}
            readOnly={true}
            onBlur={onBlur}
          />
        </div>
        {isOpenCalendar && currencyView === calendarConstant.days && (
          <section className="calendar" id="calendar-profile">
            <header className="calendar__header">
              {moment(currentDate)
                .locale(calendarConstant.es)
                .format(calendarConstant.MMMM) !==
              moment()
                .locale(calendarConstant.es)
                .format(calendarConstant.MMMM) ? (
                <button
                  className="calendar__button calendar__button--left"
                  onClick={() =>
                    previousDate(Numbers.one, calendarConstant.month)
                  }
                  role={calendarConstant.button}
                  type={calendarConstant.button}
                >
                  <AImage
                    className="calendar__button__figure"
                    url={showMoreIcon}
                    alt={calendarConstant.btnLastMonth}
                  />
                </button>
              ) : (
                <span></span>
              )}
              <h2 className="calendar__text" onClick={changeView}>
                {formatToPascalCase(
                  moment(currentDate)
                    .locale(calendarConstant.es)
                    .format(calendarConstant.MMMM)
                    .replace(calendarConstant.de, EmptyString.emptyString)
                )}
                <AImage
                  className={EmptyString.emptyString}
                  url={showMoreIcon}
                  alt={calendarConstant.openYear}
                />
              </h2>
              <button
                className="calendar__button calendar__button--right"
                onClick={() => nextDate(Numbers.one, calendarConstant.month)}
                role={calendarConstant.button}
                type={calendarConstant.button}
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt={calendarConstant.btnLastYear}
                />
              </button>
            </header>

            <div className="calendar__body">
              <div className="calendar__row">
                {days.map((day, index) => (
                  <div className="calendar__name-day" key={index}>
                    {day}
                  </div>
                ))}
              </div>

              {calendar.map((week, index) => (
                <div className="calendar__row" key={index}>
                  {week.map((day, index) => (
                    <div
                      key={index}
                      className={`calendar__day-container 
                              ${
                                day.disabled
                                  ? 'disabled'
                                  : EmptyString.emptyString
                              } 
                              ${
                                day.selected === 1
                                  ? 'selected--only'
                                  : EmptyString.emptyString
                              } 
                            `}
                      onClick={() => {
                        !day.disabled && selectDay(day);
                      }}
                    >
                      <span className="calendar__day">{day.dayOfMonth}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </section>
        )}

        {isOpenCalendar && currencyView === calendarConstant.months && (
          <section className="calendar">
            <header className="calendar__header">
              <button
                className="calendar__button calendar__button--left"
                onClick={() => previousDate(Numbers.one, calendarConstant.year)}
                role={calendarConstant.button}
                type={calendarConstant.button}
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt={calendarConstant.btnNextYear}
                />
              </button>
              <h2 className="calendar__text calendar__text--decade">
                {formatToPascalCase(
                  moment(currentDate)
                    .locale(calendarConstant.es)
                    .format(calendarConstant.MMMM)
                    .replace(calendarConstant.de, EmptyString.emptyString)
                )}
                <AImage
                  className={EmptyString.emptyString}
                  url={showMoreIcon}
                  alt={calendarConstant.openYear}
                />
              </h2>

              {moment(currentDate)
                .locale(calendarConstant.es)
                .format(calendarConstant.MMMM) !==
              moment()
                .locale(calendarConstant.es)
                .format(calendarConstant.MMMM) ? (
                <button
                  className="calendar__button calendar__button--right"
                  onClick={() => nextDate(1, calendarConstant.year)}
                  role={calendarConstant.button}
                  type={calendarConstant.button}
                >
                  <AImage
                    className="calendar__button__figure"
                    url={showMoreIcon}
                    alt={calendarConstant.btnNextMonth}
                  />
                </button>
              ) : (
                <span></span>
              )}
            </header>
            <div className="calendar__body calendar__body--months">
              {months.map((month, index) => (
                <div
                  key={index}
                  className={`calendar__item 
                      ${
                        month.selected
                          ? 'selected__item'
                          : EmptyString.emptyString
                      } 
                      ${
                        month.disabled
                          ? 'calendar__item--disabled'
                          : EmptyString.emptyString
                      }
                    `}
                  onClick={() => {
                    !month.disabled &&
                      selectADate(month, calendarConstant.days);
                  }}
                >
                  {formatToPascalCase(
                    moment(month.date)
                      .locale(calendarConstant.es)
                      .format(calendarConstant.MMM2)
                      .replace(RHs.point, EmptyString.emptyString)
                  )}
                </div>
              ))}
            </div>
          </section>
        )}

        {isOpenCalendar && currencyView === calendarConstant.years && (
          <section className="calendar">
            <header className="calendar__header">
              <button
                className="calendar__button calendar__button--left"
                onClick={() => previousDate(10, calendarConstant.year)}
                role={calendarConstant.button}
                type={calendarConstant.button}
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt={calendarConstant.btnLastYear}
                />
              </button>
              <h2 className="calendar__text calendar__text--decade">
                {formatToPascalCase(
                  moment(currentViewYear)
                    .locale(calendarConstant.es)
                    .format(calendarConstant.MMMM)
                    .replace(calendarConstant.de, EmptyString.emptyString)
                )}
                <AImage
                  className={EmptyString.emptyString}
                  url={showMoreIcon}
                  alt={calendarConstant.openYear}
                />
              </h2>

              {!years.some((year) => year.date.year() === moment().year()) ? (
                <button
                  className="calendar__button calendar__button--right"
                  onClick={() => nextDate(Numbers.ten, calendarConstant.years)}
                  role={calendarConstant.button}
                  type={calendarConstant.button}
                >
                  <AImage
                    className="calendar__button__figure"
                    url={showMoreIcon}
                    alt={calendarConstant.btnNextYear}
                  />
                </button>
              ) : (
                <span></span>
              )}
            </header>
            <div className="calendar__body calendar__body--years">
              {years.map((year, index) => (
                <div
                  key={index}
                  className={`calendar__item 
                      calendar__item--year 
                      ${
                        year.selected
                          ? 'selected__item'
                          : EmptyString.emptyString
                      } 
                      ${
                        year.disabled
                          ? 'calendar__item--disabled'
                          : EmptyString.emptyString
                      }
                    `}
                  onClick={() => {
                    !year.disabled &&
                      selectADate(year, calendarConstant.months);
                  }}
                >
                  {formatToPascalCase(
                    moment(year.date)
                      .locale(calendarConstant.es)
                      .format(calendarConstant.YYYY)
                  )}
                </div>
              ))}
            </div>
          </section>
        )}
        <div className="subtext">
          <pre className={`${error ? 'subtext-error' : 'input-subtext'}`}></pre>
        </div>
      </div>
    );
  }
);

export default ACalendarNext;
