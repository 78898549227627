import { talentoApi } from "./index";
import { IDeleteBeneficiaryBody, ISaveBeneficiaryBody } from "utils/interfaces";
import { UploadDocumentFormSchema, } from 'components/organisms/UploadDocumentsTabForm/UploadDocumentsTabForm';
import { IUploadDocumentFormSchemaColombia } from 'components/organisms/NewUploadDocumentsColombia';


const beneficiaryManagementExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    saveBeneficiaryInHiring: builder.mutation<{}, ISaveBeneficiaryBody>({
      query: (body) => ({
        url: `/hiring/beneficiary`,
        method: 'POST',
        body,
      }),
    }),

    deleteSaveBeneficiary: builder.mutation<{}, IDeleteBeneficiaryBody>({
      query: (body) => ({
        url: `/hiring/beneficiary`,
        method: 'DELETE',
        body,
      }),
    }),

    getListBeneficiary: builder.query<{ data: any[] }, { email: string }>({
      query: ({ email }) => ({
        url: `/hiring/beneficiary?email=${email}`,
      }),
    }),

    updateInsuranceRadio: builder.mutation<
      {
        message: string;
        status?: number;
      },
      {
        email: string;
      } & UploadDocumentFormSchema['beneficiaryInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateBeneficiariesChecks',
        method: 'PUT',
        body,
      }),
    }),

    updateInsuranceRadioColombia: builder.mutation<
      {
        message: string;
        status?: number;
      },
      {
        email: string;
      } & IUploadDocumentFormSchemaColombia['beneficiaryInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateBeneficiariesChecks',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useSaveBeneficiaryInHiringMutation,
  useDeleteSaveBeneficiaryMutation,
  useGetListBeneficiaryQuery,
  useUpdateInsuranceRadioMutation,
  useUpdateInsuranceRadioColombiaMutation,

} = beneficiaryManagementExtensionApi;