import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { NAME_SLICE_ONBOARDING } from "utils/constants/Onboarding/indexOnboarding";

interface OnboardingDashboard {
  step: number;
  showOnboarding: boolean;
  savedStep?: number;
}

interface OnboardingState {
  onboardingDashboard: OnboardingDashboard;
  showProfileModal: boolean;
  showHelpModal: boolean;
  showEndOnboardingModal: boolean;
}

const initialState: OnboardingState = {
  onboardingDashboard: {
    step: 0,
    showOnboarding: false,
    savedStep: undefined,
  },
  showProfileModal: false,
  showHelpModal: false,
  showEndOnboardingModal: false,
}

const onboardingSlice = createSlice({
  name: NAME_SLICE_ONBOARDING,
  initialState,
  reducers: {
    setOnboardingDashboard: (state, action: PayloadAction<OnboardingDashboard>) => {
      state.onboardingDashboard = action.payload;
    },
    setShowProfileModal: (state, action: PayloadAction<boolean>) => {
      state.showProfileModal = action.payload;
    },
    setShowHelpModal: (state, action: PayloadAction<boolean>) => {
      state.showHelpModal = action.payload;
    },
    setShowEndOnboardingModal: (state) => {
      state.showEndOnboardingModal = true;
      state.onboardingDashboard.savedStep = state.onboardingDashboard.step;
      state.onboardingDashboard.step = -1;
    },
    setResumeOnboarding: (state) => {
      if (state.onboardingDashboard.savedStep !== undefined) {
        state.onboardingDashboard.step = state.onboardingDashboard.savedStep;
        state.onboardingDashboard.savedStep = undefined;
      }
      state.showEndOnboardingModal = false;
    },
    setNextStep: (state) => {
      state.onboardingDashboard.step += 1;
    },
    setPrevtStep: (state) => {
      state.onboardingDashboard.step -= 1;
    },
    resetOnboarding: () => initialState,
  }
})

export const {
  setOnboardingDashboard,
  setShowProfileModal,
  setShowHelpModal,
  setNextStep,
  setPrevtStep,
  resetOnboarding,
  setResumeOnboarding,
  setShowEndOnboardingModal
} = onboardingSlice.actions;

export const selectOnboardingDashboard = (state: RootState) =>
  state.onboarding.onboardingDashboard;

export const selectShowProfileModal = (state: RootState) =>
  state.onboarding.showProfileModal;

export const selectShowHelpModal = (state: RootState) =>
  state.onboarding.showHelpModal;

export const selectShowEndOnboardingModal = (state: RootState) =>
  state.onboarding.showEndOnboardingModal;

export default onboardingSlice.reducer;