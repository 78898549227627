import { talentoApi } from "./index";

const miscExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getListRelationships: builder.query({
      query: ({ email }) => ({
        url: `/hiring/relationships?email=${email}`,
      }),
    }),
  }),
});

export const {
  useGetListRelationshipsQuery,

} = miscExtensionApi;