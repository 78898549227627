import "./ModalTriangle.scss"

interface IModalTriangleProps {
  trianglePosition: string;
}

const ModalTriangle = ({ trianglePosition }: IModalTriangleProps) => {
  return (
    <div className={`triangle triangle--${trianglePosition}`}></div>
  );
}

export default ModalTriangle;