import {
  helpIcon,
  homeIcon,
  profileIcon,
  helpIconActive,
  homeIconActive,
  profileIconActive,
} from 'utils/assets';

export type TabbarItemPath = 'home' | 'profile' | 'help' | 'home-hiring';

interface ITabbarItem {
  path: TabbarItemPath;
  title: string;
  icon: string;
  activeIcon: string;
  isDisabled?: boolean;
}

export const tabbarItems: ITabbarItem[] = [
  {
    path: 'home',
    title: 'Inicio',
    icon: homeIcon,
    activeIcon: homeIconActive,
  },
  {
    path: 'home-hiring',
    title: 'Inicio',
    icon: homeIcon,
    activeIcon: homeIconActive,
  },
  {
    path: 'profile',
    title: 'Perfil',
    icon: profileIcon,
    activeIcon: profileIconActive,
    isDisabled: false,
  },
  {
    path: 'help',
    title: 'Ayuda',
    icon: helpIcon,
    activeIcon: helpIconActive,
    isDisabled: false,
  },
];
