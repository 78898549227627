/* eslint-disable react/prop-types */
import { AButton, AImage } from "components/atoms";
import { ALT_ICON_DOCUMENT } from "./constans";
import documentIconWhite from '../../../utils/assets/images/documentIconWhite.png';
import styles from "./TechnicalTestLinkCard.module.scss"

interface TechnicalTestLinkCardProps {
  title: string;
  url: string;
}

const TechnicalTestLinkCard: React.FC<TechnicalTestLinkCardProps> = ({ url, title }) => {
  return ( 
    <div className={styles.TechnicalTestLinkCard}>
      <div className={styles.TechnicalTestLinkCard__iconContainer}>
        <AImage
          alt={ALT_ICON_DOCUMENT}
          url={documentIconWhite}/>
      </div>
      <AButton tag='a' url={url} title={title} target='_blank' rel='noopener noreferrer' />
      &nbsp;
    </div>
   );
}
 
export default TechnicalTestLinkCard; 