import { regexPatterns } from './dictionaries';

export const formatValueForDisplay = (value: string | number): string => {
  if (!value) return '';
  if (typeof value === 'number') value = value.toString();

  const valueWithoutDots = value.replace(regexPatterns.removePoints, '');
  const reversedWithDots = valueWithoutDots
    .split('')
    .reverse()
    .join('')
    .replace(regexPatterns.threeDigitsNotAtEnd, regexPatterns.replacementWithDot);

  return reversedWithDots.split('').reverse().join('');
};

export const removeDots = (value: string): string => {
  return value.replace(regexPatterns.removePoints, '');
};
