import { Loader } from 'components/atoms';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import './LoadingPage.scss';

interface IPropsLoader {
    fullScreen?: Boolean;
}

export const LoadingPage = ({ fullScreen }: IPropsLoader) => {
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);

    return (
        <div className={`loading__page ${fullScreen && 'loading__page-fullScreen'}`} data-testid="loading">
            <Loader />
        </div>
    )
}