import axios from "axios"

const url = process.env.REACT_APP_BITACORA_TOKEN || ''

export const getToken = async () => {
    try {
      const response = await axios.get(url);
      return response.data.token;
    } catch (error) {
      console.error("Error obteniendo el token:", error);
      return '';
    }
  };