import { PropsWithChildren, useEffect, useState } from 'react'
import './WelcomeModal.scss';
import { AButton, Loader } from '../../atoms';
import { useGetProfileDataFromHubspotQuery, useGetStagesQuery } from 'services/talento';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo } from 'store';
import { IResponseStages } from 'utils/interfaces';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { IS_APLICATIVO_INGRESO_DISABLED, LENGTH_DESCRIPTION_CUT } from './constans';

type _WelcomeModalProps = {
    setIsWelcome: (state: boolean) => void;
}

export type WelcomeModalProps = PropsWithChildren<_WelcomeModalProps>

export const WelcomeModal = ({ setIsWelcome }: WelcomeModalProps) => {
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);
    const { email } = useAppSelector(selectUserInfo);
    const { data = {} as IResponseStages, isLoading: isLoadingStages } = useGetStagesQuery({ email });
    const descriptionCut = t("welcomeModalDescription").split('~')
    const [intro, outro] = descriptionCut.length >= LENGTH_DESCRIPTION_CUT ? descriptionCut : ["", ""];

    const [name, setName] = useState('')
    const [vacant, setVacant] = useState('')
    const [shouldGoSelect, setShouldGoSelect] = useState<boolean>(true);
    const { data: userHubspotData } = useGetProfileDataFromHubspotQuery({ email })
    
    useEffect(() => {
        if (userHubspotData?.data[0].properties.aplicativo_ingreso === IS_APLICATIVO_INGRESO_DISABLED) {
            setShouldGoSelect(false)
        }
    }, [userHubspotData]);

    useEffect(() => {
        if (!isLoadingStages) {
            setName(data.data.fullName.split(' ')[0])
            setVacant(`vacante ${data.data.vacant}`)
        }
    }, [isLoadingStages])

    if (isLoadingStages) {
        return <Loader/>
    }

    return (
        <section className='welcome-modal__wrapper'>
            <div className='welcome-modal__header'></div>
            <div className='welcome-modal__information'>
                <div className='information__texts'>
                    <h2 className='information__title'>{`Hola ${name}`}</h2>
                    <p className='information__description'>
                    {shouldGoSelect ? (
                        <>
                            {intro} <span className="information__bold">{vacant}</span> {outro}
                        </>
                        ) : (
                            t("welcomeModalDescriptionContract")
                        )}
                    </p>
                    <p className='information__indication'>{t("indicationWelcomeModal")}</p>
                </div>
                <div className='information__button-wrapper'>
                    <AButton 
                        tag={'button'}
                        className='button-primary welcome-modal__button'
                        handleClick={() => setIsWelcome(false)}
                    >
                        Continuar
                    </AButton>
                </div>
            </div>
        </section>
    )
}

export default WelcomeModal