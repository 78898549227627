import { ISaveUserBody } from "utils/interfaces";
import { talentoApi } from "./index";

const userManagementExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    saveUser: builder.mutation<{}, ISaveUserBody>({
      query: (body) => ({
        url: `/hiring/hiring/save-user`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSaveUserMutation,
} = userManagementExtensionApi;