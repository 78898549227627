import './Tooltip.scss';

interface IProps {
  icon: JSX.Element[] | JSX.Element;
  text: string;
  variant?: 'white' | 'purple';
  additionalText?: string;
}

export const Tooltip = ({ icon, text, variant = 'white', additionalText }: IProps) => {
  const formattedText = text.replace(/,/g, '<br/>');
  const tooltipText =
    variant === 'white'
      ? `${additionalText ? additionalText : ''} ${formattedText}`
      : formattedText;

  return (
    <div
      className="tooltip__container"
      role="tooltip"
      data-testid="tooltip__container"
    >
      {icon}
      <div
        className={`tooltip__text tooltip__text--${variant}`}
        dangerouslySetInnerHTML={{ __html: tooltipText }}
      />
    </div>
  );
};
