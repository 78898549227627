import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Input } from 'components/atoms';
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import { useGetGendersQuery } from 'services/sap';
import { useUpdateOrganizationalInfoHiringMutation } from 'services/talento';
import { catalogoLenguage, catalogoPersonal } from 'utils/helpers/dictionaries';

import { IAdminPanelForm } from '../AdminPanelForm.schema';

import ChapterSelect from './SelectContainers/ChapterSelect';
import SpecialitySelect from './SelectContainers/SpecialitySelect';
import TechnicalLevelSelect from './SelectContainers/TechnicalLevelSelect';
import TechnologySelect from './SelectContainers/TechnologySelect';

import styles from './OrganizationSection.module.scss';

export default function OrganizationSection() {
  const { email } = useParams();
  const { control, getValues, setValue, formState } = useFormContext<IAdminPanelForm>();
  const [updateOrganizationalInfoHiring] = useUpdateOrganizationalInfoHiringMutation();
  const { data: chapter, isLoading: isChapterLoading } = useGetGendersQuery<{
    data: { name: string; externalCode: string }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.FOJobFunction,
    filter: [catalogoLenguage.name, catalogoLenguage.externalCode],
  });
  const { data: speciality, isLoading: isSpecialityLoading } = useGetGendersQuery<{
    data: { externalName: string; externalCode: string }[];
    isLoading: boolean;
  }>({
    catalogName: 'cust_Seniority_Level_Especialidad',
    languages: catalogoLenguage.ES,
    filter: [catalogoLenguage.externalName, catalogoLenguage.externalCode],
  });
  const { data: technicalLevel, isLoading: isTechnicalLevelLoading } = useGetGendersQuery<{
    data: { label_es_ES: string; externalCode: string }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.Seniority,
    languages: [catalogoLenguage.ES, catalogoLenguage.externalCode],
  });

  const { data: technologyLevel, isLoading: isTechnologyLevelLoading } = useGetGendersQuery<{
    data: { externalName: string; externalCode: string }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.cust_Teconologia,
    filter: [catalogoLenguage.externalName, catalogoLenguage.externalCode],
  });

  useEffect(() => {
    if (!chapter || !speciality || !technologyLevel || !technicalLevel) return;
    console.log({
      chapter,
      speciality,
      technologyLevel,
      technicalLevel,
    });
    submitData();
  }, [chapter, speciality, technologyLevel, technicalLevel]);

  async function submitData() {
    const { organizationalInformation, growInformation } = getValues();
    const { data } = await updateOrganizationalInfoHiring({
      department: organizationalInformation.department,
      division: organizationalInformation.division,
      email: email ?? '',
      referred: organizationalInformation.referred,
      updateGrowRequest: growInformation.map((grow) => ({
        specialty: grow.specialty,
        chapter: grow.chapter,
        idChapterSap: chapter?.find(({ name }) => name === grow.chapter)?.externalCode ?? null,
        idSpecialtySap:
          speciality?.find(({ externalName }) => externalName === grow.specialty)?.externalCode ??
          null,
        idTechnologySap:
          technologyLevel?.find(({ externalName }) => externalName === grow.technology)
            ?.externalCode ?? null,
        idTechnicalLevelSap:
          technicalLevel?.find(({ label_es_ES }) => label_es_ES === grow.technicaLevel)
            ?.externalCode ?? null,
        technology: grow.technology,
        technicaLevel: grow.technicaLevel,
        position: grow.position ?? 0,
      })),
    }).unwrap();
    if (data) {
      setValue('growInformation', data.growInformation);
      setValue('organizationalInformation', data.organizationalInformation);
    }
  }

  return (
    <div className={styles.OrganizationSection}>
      <h2 className={styles.OrganizationSection__Title}>Información organizacional</h2>
      <div className={styles.OrganizationSection__Grid}>
        <Controller
          control={control}
          name='organizationalInformation.division'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <CatalogSelect
              catalog={catalogoPersonal.FODivision}
              filter={[catalogoLenguage.name]}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel='División'
              htmlForLabel='División'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='organizationalInformation.department'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <CatalogSelect
              catalog={catalogoPersonal.FODepartment}
              filter={[catalogoLenguage.name]}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Departamento'
              htmlForLabel='Departamento'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.0.chapter'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <ChapterSelect
              chapter={chapter}
              isLoading={isChapterLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.0.idChapterSap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Chapter 1'
              htmlForLabel='Chapter 1'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.1.chapter'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <ChapterSelect
              chapter={chapter}
              isLoading={isChapterLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.1.idChapterSap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Chapter 2 (opcional)'
              htmlForLabel='Chapter 2 (opcional)1'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.0.specialty'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <SpecialitySelect
              chapter={speciality}
              isLoading={isSpecialityLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.0.idSpecialtySap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Especialidad 1'
              htmlForLabel='Especialidad 1'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.1.specialty'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <SpecialitySelect
              chapter={speciality}
              isLoading={isSpecialityLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.1.idSpecialtySap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Especialidad 2 (opcional)'
              htmlForLabel='Especialidad 2 (opcional)'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.0.technology'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <TechnologySelect
              chapter={technologyLevel}
              isLoading={isTechnologyLevelLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.0.idTechnologySap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Tecnología 1 (opcional)'
              htmlForLabel='Tecnología 1 (opcional)'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.1.technology'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <TechnologySelect
              chapter={technologyLevel}
              isLoading={isTechnologyLevelLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.1.idTechnologySap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Tecnología 2 (opcional)'
              htmlForLabel='Tecnología 2 (opcional)'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.0.technicaLevel'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <TechnicalLevelSelect
              chapter={technicalLevel}
              isLoading={isTechnicalLevelLoading}
              value={{
                value,
                label: value ?? '',
              }}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.0.idTechnicalLevelSap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Nivel técnico 1 (opcional)'
              htmlForLabel='Nivel técnico 1 (opcional)'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name='growInformation.1.technicaLevel'
          render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
            <TechnicalLevelSelect
              value={{
                value,
                label: value ?? '',
              }}
              chapter={technicalLevel}
              isLoading={isTechnicalLevelLoading}
              handleChange={({ value, externalCode }) => {
                onChange(value);
                setValue('growInformation.1.idTechnicalLevelSap', externalCode);
                submitData();
              }}
              error={error !== undefined}
              textLabel='Nivel técnico 2 (opcional)'
              htmlForLabel='Nivel técnico 2 (opcional)'
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <div className={styles.OrganizationSection__ReferredContainer}>
          <Controller
            control={control}
            name='organizationalInformation.referred'
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                error={error !== undefined}
                id='referred'
                label='Referido por (opcional)'
                subtext={error?.message}
                {...field}
                onBlur={(e) => {
                  field.onBlur();
                  submitData();
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
