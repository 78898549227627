import { IResponseAdmissionData, IResponseProfileHubspot } from "utils/interfaces";
import { talentoApi } from "./index";


const hubspotExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDataFromHubspot: builder.query<
      IResponseProfileHubspot,
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hubspot/contacts/search?email=${email}`,
      }),
    }),
    getAdmissionDate: builder.query<IResponseAdmissionData, { email: string }>({
      query: ({ email }) => ({
        url: `/hubspot/business/admission-date?email=${email}`,
      }),
    }),
  }),
});

export const {
  useGetProfileDataFromHubspotQuery,
  useGetAdmissionDateQuery,
} = hubspotExtensionApi;