import React, { useEffect, useRef, useState } from 'react';
import { selectOnboardingDashboard, selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { IStage } from 'utils/interfaces/store/user.interfaces'
import { stageState } from 'utils/helpers';
import { Stage } from 'components/molecules';
import './StagesRender.scss';
import OnboardingModal from 'components/molecules/OnboardingModal/OnboardingModal.component';
import { LOCKED, ONBOARDING_MODALS } from 'utils/constants/Onboarding/indexOnboarding';
import { Numbers } from 'utils/helpers/dictionaries';


interface IProps {
  stages: IStage[];
};

export const StagesRender = ({ stages }: IProps) => {
  const { userState } = useAppSelector(selectUserInfo);
  const { vacant } = useAppSelector(selectVacantInfo);
  const { showOnboarding, step } = useAppSelector(selectOnboardingDashboard);
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerStagesRenderRef = useRef<HTMLDivElement>(null);
  const [showStagesModal, setShowOnboardingModal] = useState<boolean>(false);

  useEffect(() => {
    if (step === 4 && containerStagesRenderRef.current) {
      containerStagesRenderRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [step]);

  useEffect(() => {
    if (stages[0]?.stage === 0) {
      setIsActive(true)
    }
    if (stages[0] && vacant) {
      const stageStatus = stageState({
        status: stages[0].status,
        stage: stages[0].stage,
        currentStage: vacant.idStage,
        statusUser: userState,
      });
      setShowOnboardingModal(stageStatus === LOCKED);
    }
  }, []);
  return (
    <div className={`stagesRender  ${stages.length === 0 ? 'stagesRender--hidden' : undefined}`} ref={containerStagesRenderRef}>
      {
        stages.length && stages.map(({ id, name, icons, status, stage }) => (
          <div className='btn_container' key={id}>
            <Stage
              id={id}
              icons={icons}
              name={name}
              state={stageState({ status, stage, currentStage: vacant!.idStage, statusUser: userState })}
              idStage={id}
              step={step}
            />
          </div>
        ))
      }
      {!isActive && showOnboarding && step === 4 && showStagesModal && (
        <OnboardingModal
          title={ONBOARDING_MODALS[4].TITLE}
          text={ONBOARDING_MODALS[4].TEXT}
          step={Numbers.four}
          className={ONBOARDING_MODALS[4].CLASSNAME}
          trianglePosition={ONBOARDING_MODALS[4].TRIANGLE_POSITION}
        />
      )}
    </div>
  )
}