import { IHelpsOption, IResponse, IResponseHelpsOption, ISendImage } from "utils/interfaces";
import { talentoApi } from "./index";

const helpExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getListHelpOptions: builder.query<IHelpsOption, any>({
      query() {
        return {
          url: `/stages/list-help-options`,
        };
      },
    }),

    postListHelpOptions: builder.mutation<IResponse, IResponseHelpsOption>({
      query: ({ option, email, message, id }) => ({
        url: `/stages/help`,
        method: 'POST',
        body: {
          option,
          email,
          message,
          id,
        },
      }),
    }),

    sendImage: builder.mutation<IResponse, ISendImage>({
      query: (data) => ({
        url: `/stages/help`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetListHelpOptionsQuery,
  usePostListHelpOptionsMutation,
  useSendImageMutation,
} = helpExtensionApi;