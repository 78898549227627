import { talentoApi } from "./index";
import { produce } from 'immer';
import { IUploadDocumentFormSchemaInternship } from 'components/organisms/UploadDocumentsInternship';
import { IAdditionalInformation } from 'components/organisms/UploadDocumentsTabForm/UploadDocumentsTabForm';

const additionalInfoExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdditionalInformationColombia: builder.query<
      { data: IUploadDocumentFormSchemaInternship },
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/candidates/getUserAdditionalInformation?email=${email}`,
      }),
      transformResponse: (response: {
        data: IUploadDocumentFormSchemaInternship;
      }) => {
        let newResponse = response.data;

        newResponse = produce(newResponse, (draft) => {
          if (draft.loadedDocuments.cert_eps) {
            draft.affiliateInformationResponse.epsCertificate =
              draft.loadedDocuments.cert_eps;
          }

          if (draft.loadedDocuments.cert_pension) {
            draft.affiliateInformationResponse.pensionCertificate =
              draft.loadedDocuments.cert_pension;
          }

          if (draft.loadedDocuments.cert_cesantias) {
            draft.affiliateInformationResponse.cesantiasCertificate =
              draft.loadedDocuments.cert_cesantias;
          }

          if (draft.loadedDocuments.cert_bancario) {
            draft.bankInformation.cert_account =
              draft.loadedDocuments.cert_bancario;
          }

          if (draft.hasBank !== undefined) {
            draft.bankInformation.hasBank = draft.hasBank;
          }

          if (draft.wantsBancolombiaAcc !== undefined) {
            draft.bankInformation.wantsBancolombiaAcc =
              draft.wantsBancolombiaAcc;
          }

          if (draft.affiliateInformationResponse === null) {
            draft.affiliateInformationResponse = {
              epsCertificate: undefined as any,
              hasEps: undefined as any,
              wantedEpsAffiliate: undefined as any,
              hasPension: undefined as any,
              pensionCertificate: undefined as any,
              wantedPensionFund: undefined as any,
              hasSeveranceFund: undefined as any,
              cesantiasCertificate: undefined as any,
              wantedSeveranceFund: undefined as any,
            };
          }
        });

        return { data: newResponse };
      },
    }),

    getAdditionalInformation: builder.query<
      { data: IAdditionalInformation },
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/candidates/getUserAdditionalInformation?email=${email}`,
      }),
      transformResponse: (response: { data: IAdditionalInformation }) => {
        const DATE_START_INDEX = 0;
        const DATE_END_INDEX = 10;

        let newResponse = response.data;

        newResponse = produce(newResponse, (draft) => {
          const defaultValue = [
            {
              id: 0,
              currency: 'PER',
              company: '',
              position: '',
              salary: '',
              startDate: '',
              endDate: '',
            },
          ];

          draft.workInformation.workHistory =
            draft.workInformation.workHistory.length === 0
              ? defaultValue
              : draft.workInformation.workHistory;
        });

        newResponse = produce(newResponse, (draft) => {
          for (let history of draft.workInformation.workHistory) {
            const currentDate = '';
            history.startDate = history.startDate
              ? new Date(history.startDate)
                .toISOString()
                .slice(DATE_START_INDEX, DATE_END_INDEX)
              : currentDate;
            history.endDate = history.endDate
              ? new Date(history.endDate)
                .toISOString()
                .slice(DATE_START_INDEX, DATE_END_INDEX)
              : currentDate;
          }
        });

        newResponse = produce(newResponse, (draft) => {
          if (!draft.workInformation.pensionInformation) {
            draft.workInformation.pensionInformation = {
              isAffiliate: undefined as any,
              isPensioner: undefined as any,
              pensionType: '',
              pensionEntity: '',
            };
          }
        });

        newResponse = produce(newResponse, (draft) => {
          if (draft.loadedDocuments.cert_renta) {
            draft.workInformation.cert_renta = draft.loadedDocuments.cert_renta;
          }
        });

        return { data: newResponse };
      },
    }),
  }),
});

export const {
  useGetAdditionalInformationColombiaQuery,
  useGetAdditionalInformationQuery,

} = additionalInfoExtensionApi;