export const NAME_SLICE_ONBOARDING = "onboarding"

export const ONBOARDING_MODALS = [
  {
    TITLE: "¿Listo para despegar?",
    TEXT: "Esta guía rápida por la plataforma te servirá para familiarizarte con todas sus funciones de forma sencilla",
    STEP: 0,
    CLASSNAME: "modalOnboardingZero"
  },
  {
    TITLE: "Descripción de la vacante",
    TEXT: "Aquí puedes consultar la descripción de la vacante y los detalles sobre el rol al que te estas postulando",
    STEP: 1,
    TRIANGLE_POSITION: "top"
  },
  {
    TITLE: "Tu progreso",
    TEXT: "Con cada actividad completada verás cómo la barra de progreso se irá llenando",
    STEP: 2,
    CLASSNAME: "modalOnboardingTwo",
    TRIANGLE_POSITION: "top"
  },
  {
    TITLE: "Etapas vigentes y completadas",
    TEXT: "Aquí podrás ver las actividades que debes completar para avanzar en tu proceso. A medida que completes cada actividad, aparecerá en la pestaña ",
    BOLD: "Etapas completadas",
    STEP: 3,
    CLASSNAME: "modalOnboardingThree",
    TRIANGLE_POSITION: "left"
  },
  {
    TITLE: "Próximas etapas",
    TEXT: "Estas son actividades futuras que deberás completar a medida que avances exitosamente en tu proceso",
    STEP: 4,
    CLASSNAME: "modalOnboardingFour",
    TRIANGLE_POSITION: "left"
  },
  {
    TITLE: "Detalles de la actividad ",
    TEXT: "Aquí encontrarás información clave para realizar cada etapa, como la descripción y recomendaciones ",
    STEP: 5,
    CLASSNAME: "modalOnboardingFive",
    TRIANGLE_POSITION: "right"
  },
  {
    TITLE: "¿Quieres abandonar la guía? ",
    TEXT: "Esta guía te ayudará a aprovechar al máximo la plataforma, si la abandonas no podrás retomarla después",
    STEP: -1,
    CLASSNAME: "modalOnboardingZero"
  },
  {
    TITLE: "Perfil",
    TEXT: "Aquí puedes revisar, editar y completar la información de tu perfil, así como cambiar la contraseña.",
    TRIANGLE_POSITION: "left"
  },
  {
    TITLE: "Ayuda",
    TEXT: (
      <>
        Selecciona el inconveniente que necesitas resolver, al dar clic en{" "}
        <b>Continuar</b> te pediremos que proporciones más información.
      </>
    ),
    TRIANGLE_POSITION: "left",
    CLASSNAME: "info-tooltip info-tooltip--help"
  }
]

export const BACK = "Atrás"
export const START = "Empezar"
export const NEXT = "Siguiente"
export const SUPER = "¡Súper!"
export const LEAVE = "Abandonar"
export const CONTINUE = "Continuar"
export const LOCKED = "locked"

export enum onboardingStaus {
  canceled = 0,
  ongoing = 1,
  completed = 2,
}

export enum onboardingDashboard {
  onboarding = "onboarding",
  help = "help",
  profile = "profile",
}

