import {
  IConfirmCodeBody,
  INewPasswordBody,
  IProfile,
  IResendCode,
  IResponse,
  IRestorePassword
} from "utils/interfaces";
import { talentoApi } from "./index"; 

const securityExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    restorePassword: builder.mutation<IResponse, IRestorePassword>({
      query({ email }) {
          return {
              url: '/security/authentication/send-code',
              method: 'POST',
              body: { email },
          };
      },
    }),
    confirmCode: builder.mutation<IResponse, IConfirmCodeBody>({
      query({ email, code }) {
          return {
              url: '/security/authentication/verify-code',
              method: 'POST',
              body: {
                  email,
                  code,
              },
          };
      },
    }),
    newPassword: builder.mutation<IResponse, INewPasswordBody>({
      query: ({ email, newPassword, confirmedPassword }) => ({
          url: '/security/authentication/restore-password',
          method: 'POST',
          body: {
              email,
              newPassword,
              confirmedPassword,
          },
      }),
    }),
    resendCode: builder.mutation<IResponse, IResendCode>({
      query: ({ email }) => ({
          url: '/security/authentication/resend-code',
          method: 'POST',
          body: { email },
      }),
    }),
    getProfileData: builder.query<IProfile, { email: string }>({
      query: ({ email }) => `/security/users?email=${email}`,
      transformResponse: (response: { data: IProfile }) => response.data,
    }),
  })
})


export const {
  useRestorePasswordMutation,
  useConfirmCodeMutation,
  useNewPasswordMutation,
  useResendCodeMutation,
  useGetProfileDataQuery,
} = securityExtensionApi;