import { AButton, AImage } from "components/atoms";
import "./OnboardingModal.scss"
import { xCloseIcon } from "utils/assets";
import { useDispatch } from "react-redux";
import { setNextStep, setOnboardingDashboard, setPrevtStep, setResumeOnboarding, setShowEndOnboardingModal } from "store";
import ModalTriangle from "components/atoms/ModalTriangle/ModalTriangle.component";
import { BACK, CONTINUE, LEAVE, NEXT, onboardingDashboard, onboardingStaus, START, SUPER } from "utils/constants/Onboarding/indexOnboarding";
import { useSetOnboardingMutation } from "services/talentoAPI/tracking";
import { Numbers } from "utils/helpers/dictionaries";
import React from "react";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  text: string | React.ReactNode;
  email?: string;
  step: number;
  className?: string;
  bold?: string;
  trianglePosition?: string;
  toEnd?: boolean;
}

const OnboardingModal = ({ title, text, step, className, trianglePosition, toEnd, bold, email }: IProps) => {
  const dispatch = useDispatch();
  const [setOnboarding] = useSetOnboardingMutation()

  return (
    <div className={`onboardingModal ${className ?? className} ${toEnd ? "onboardingModal--dark" : ""}`}>

      {trianglePosition && (
        <ModalTriangle trianglePosition={trianglePosition} />
      )}

      {!toEnd && (
        <div
          className="onboardingModal__headerCloseContainer"
          onClick={() => { dispatch(setShowEndOnboardingModal()) }}
        >
          <AImage alt="Cerrar modal" url={xCloseIcon} />
        </div>
      )}

      <div>
        <h3 className={`onboardingModal__headerTitle ${toEnd ? "onboardingModal__headerTitle--dark" : ""}`}>{title}</h3>
        <p className={`onboardingModal__text ${toEnd ? "onboardingModal__text--dark" : ""}`}>
          {text} {bold && <b>{bold}</b>}
        </p>
      </div>

      <div className={`onboardingModal__buttonsCtn ${step === 0 || toEnd ? "onboardingModal__buttonsCtn--center" : ""}`}>
        {step > Numbers.zero && <p className="onboardingModal__step">{step} de 5</p>}
        {step > Numbers.one && (
          <AButton
            tag="button"
            type="button"
            className={`go-back-btn`}
            title={BACK}
            handleClick={() => dispatch(setPrevtStep())}
          />
        )}

        {!toEnd && (
          step < 5 ? (
            <AButton
              tag="button"
              type="button"
              className="infoModal__button button-primary"
              title={step === 0 ? START : NEXT}
              handleClick={() => dispatch(setNextStep())}
            />
          ) : (
            <AButton
              tag="button"
              type="button"
              className="infoModal__button button-primary"
              title={SUPER}
              handleClick={() => {
                setOnboarding({
                  email: email ?? "",
                  dashboard: onboardingDashboard.onboarding,
                  status: onboardingStaus.completed
                })
                dispatch(setOnboardingDashboard({ showOnboarding: false, step: Numbers.zero }))
              }}
            />
          )
        )}

        {toEnd && (
          <>
            <AButton
              tag="button"
              type="button"
              className="leave-btn"
              title={LEAVE}
              handleClick={() => {
                setOnboarding({
                  email: email ?? "",
                  dashboard: onboardingDashboard.onboarding,
                  status: onboardingStaus.canceled
                })
                dispatch(setOnboardingDashboard({ showOnboarding: false, step: Numbers.zero }))
              }}
            />
            <AButton
              tag="button"
              type="button"
              className="infoModal__button button-primary"
              title={CONTINUE}
              handleClick={() => dispatch(setResumeOnboarding())}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default OnboardingModal;