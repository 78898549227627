import { talentoApi } from "./index";

const catalogsExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    afpCatalog: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/afp-catalogue?countryIso=PER',
      }),
    }),

    pensionCatalog: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/pension-catalogue?countryIso=PER',
      }),
    }),

    pensionCatalogCol: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/pension-catalogue?countryIso=COL',
      }),
    }),

    bankData: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/bank-catalogue?countryIso=PER',
      }),
    }),

    epsData: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/eps-catalogue?countryIso=COL',
      }),
    }),

    cesantiasData: builder.query<{ data: { id: number; name: string }[] }, any>(
      {
        query: () => ({
          url: '/hiring/candidates/severance-catalogue?countryIso=COL',
        }),
      }
    ),

    colombiaBankData: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/bank-catalogue?countryIso=COL',
      }),
    }),

    GetListRelations: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/relationship-catalogue?countryIso=PER',
      }),
    }),

    GetListRelationsCol: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/relationship-catalogue?countryIso=COL',
      }),
    }),

    getListDocumentType: builder.query({
      query: ({ country }) => ({
        url: `/hiring/candidates/document-type?country=${country}`,
      }),
    }),
  }),
});

export const {
  useAfpCatalogQuery,
  usePensionCatalogQuery,
  usePensionCatalogColQuery,
  useBankDataQuery,
  useEpsDataQuery,
  useCesantiasDataQuery,
  useColombiaBankDataQuery,
  useGetListRelationsQuery,
  useGetListRelationsColQuery,
  useGetListDocumentTypeQuery,

} = catalogsExtensionApi;