import { talentoApi } from "./index";
import { IUploadDocumentFormSchemaColombia } from 'components/organisms/NewUploadDocumentsColombia';
import { IUploadDocumentFormSchemaInternship } from 'components/organisms/UploadDocumentsInternship';

const affiliateInfoExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    saveAffiliateInformation: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
      } & IUploadDocumentFormSchemaColombia['affiliateInformationResponse']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserAffiliateInformation',
        method: 'POST',
        body,
      }),
    }),

    saveAffiliateInformationInternship: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
      } & IUploadDocumentFormSchemaInternship['affiliateInformationResponse']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserAffiliateInformation',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSaveAffiliateInformationMutation,
  useSaveAffiliateInformationInternshipMutation
} = affiliateInfoExtensionApi;