import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm, useFormContext, useFormState } from 'react-hook-form';
import { IAdminPanelForm, AdminPanelFormSchema } from './AdminPanelForm.schema';
import {
  useGetInfoCandidateQuery,
  useRehireSapMutation,
  useUpdateOrganizationalInfoHiringMutation,
  useUpdateStatusHiringMutation,
  useUpdateStatusSapHiringMutation,
} from 'services/talento';
import { useMemo, useEffect, useState } from 'react';
import { useTabFormValidation } from 'hooks/useTabFormValidation';
import VerticalTabPanel from 'components/molecules/VerticalTabPanel';
import { Modal } from 'components/organisms/Modal/Modal.components';
import styles from './AdminPanelForm.module.scss';
import { arrowLeftPanel } from 'utils/assets';
import AlertModalCard from 'components/atoms/AlertModalCard/AlertModalCard.component';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import PersonalDataSection from './PersonalDataSection';
import ContactDataSection from './ContactDataSection';
import { ExternalCodesProvider } from 'utils/helpers/ExternalCodesContext';
import EmploymentSection from './EmploymentSection';
import { AcademicDataSection } from './AcademicDataSection';
import OrganizationSection from './OrganizationSection';
import iconError from 'utils/assets/images/iconError.svg';
import iconSuccess from 'utils/assets/images/checkIcon.svg';
import HeaderAdmin from './HeaderAdmin.component';
import { LoaderCataloge } from 'components/atoms/LoaderCataloge/LoaderCataloge.components';
import { Auth } from 'aws-amplify';
import {
  ModalOptions,
  UpdateStatusSapHiring,
  fetchEmployeeInfo,
  updateStatusHiring as updateStatusHiringFunction,
} from './panelUtils';
import { catalogoLenguage } from 'utils/helpers/dictionaries';
import { AButton, Loader } from 'components/atoms';
import { TypeAlertValues } from 'components/molecules/ModalAlert/ModalAlert.components';
import {
  ALERT_MODAL_DESCRIPTION,
  IN_PROGRESS_MODAL_TITLE,
  REHIRE_ERROR_MODAL_TITLE,
  SUCCESS_MODAL_TITLE,
} from 'utils/constants/adminPanelFormConstants';
import { ICustomError } from 'utils/interfaces';
import Footer from 'components/molecules/Footer/Footer.component';

enum TabClickedState {
  clicked,
  notClicked,
}

export default function AdminPanelForm() {
  const { email } = useParams();
  const { data, isLoading, refetch } = useGetInfoCandidateQuery<{
    data: { data: IAdminPanelForm };
    isLoading: boolean;
  }>({ email });
  
  const [updateOrganizationalInfoHiring] =
    useUpdateOrganizationalInfoHiringMutation();

  async function submitData() {
    const { organizationalInformation, growInformation } = data?.data
    await updateOrganizationalInfoHiring({
      department: organizationalInformation.department,
      division: organizationalInformation.division,
      email: email ?? '',
      referred: organizationalInformation.referred,
      updateGrowRequest: growInformation.map((grow:any) => ({
        specialty: grow.specialty,
        chapter: grow.chapter,
        idChapterSap: grow.idChapterSap,
        idSpecialtySap: grow.idSpecialtySap,
        idTechnologySap: grow.idTechnologySap,
        idTechnicalLevelSap: grow.idTechnicalLevelSap,
        technology: grow.technology,
        technicaLevel: grow.technicaLevel,
        position: grow.position ?? 0,
      })),
    }).unwrap();
  }

  useEffect(() => {
    if (!data) return;
    submitData();
  }, [data]);
  const [rehireEmployeeMutation] = useRehireSapMutation();
  const [updateStatusHiring] = useUpdateStatusHiringMutation();
  const [updateStatusSapHiring] = useUpdateStatusSapHiringMutation();
  const [tabsClicked, setTabsClicked] = useState<TabClickedState[]>([
    TabClickedState.clicked,
    TabClickedState.notClicked,
    TabClickedState.notClicked,
    TabClickedState.notClicked,
    TabClickedState.notClicked,
  ]);

  const areAllTabsInteractedWith = tabsClicked.every(
    (tab) => tab === TabClickedState.clicked
  );

  const [confirmModalOptions, setConfirmModalOptions] = useState<ModalOptions>({
    message: '',
    type: TypeAlertValues[TypeAlertValues.inProgress],
    isOpen: false,
    confirmButton: false,
    cancelButton: false,
    isLoading: true,
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: zodResolver(AdminPanelFormSchema),
    defaultValues: useMemo(() => {
      return data?.data ?? {};
    }, [data]),
  });

  const formState = useFormState({ control: form.control });

  const isFormValid =
    areAllTabsInteractedWith && Object.keys(formState.errors).length === 0;

  useEffect(() => {
    form.reset(data?.data);
  }, [form.reset, data]);

  const fields: Array<keyof IAdminPanelForm>[] = [
    [
      'names',
      'secondName',
      'lastname',
      'secondLastname',
      'birthdate',
      'documentType',
      'documentNumber',
      'gender',
      'civilStatus',
      'bloodGroup',
      'shirtSize',
      'preferredName',
    ],
    [
      'countryOfResidence',
      'departamentOfResidence',
      'cityOfResidence',
      'address',
      'phone',
      'complementaryAddresses',
      'addressType',
      'email',
    ],
    ['role', 'compensationInformation', 'position', 'contractType'],
    ['organizationalInformation', 'growInformation'],
    ['professionalTitle', 'professionalCard', 'cv'],
  ];

  const { isTabValid, validateLastTab } = useTabFormValidation({
    form,
    fields,
  });

  function handleTabClick(tab: number) {
    validateLastTab(tab);
    setTabsClicked((prev) => {
      const newTabs = [...prev];
      newTabs[tab] = TabClickedState.clicked;
      return newTabs;
    });
  }

  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const PREVIOUS_LOCATION = -1;

  const onGoBack = () => {
    setModal(true)
  };

  const handleConfirm = async () => {
    setConfirmModalOptions({
      ...confirmModalOptions,
      isOpen: true,
      message: IN_PROGRESS_MODAL_TITLE,
      type: TypeAlertValues[TypeAlertValues.inProgress],
    });

    if (!email) return;

    const {
      attributes: { email: adminEmail },
    } = await Auth.currentAuthenticatedUser();

    const splittedEmail = adminEmail.split(catalogoLenguage.signal);

    const idNumber = form.getValues('documentNumber')

    const attempts = 3;

    const { externalCode, originalStartDateMilliseconds } =
      await fetchEmployeeInfo(idNumber);

    if (
      originalStartDateMilliseconds &&
      originalStartDateMilliseconds < Date.now()
    ) {
      let error = 0
      let rehireEmployeeResponse
      do {
        rehireEmployeeResponse = await rehireEmployeeMutation({
          email: email ?? '',
          externalCode: externalCode ?? '',
        });
        if ('error' in rehireEmployeeResponse) {
          error = (rehireEmployeeResponse.error as ICustomError)?.status ?? 0
        } else error = 0
      } while (error === 504)
      if ('error' in rehireEmployeeResponse) {
        setConfirmModalOptions({
          ...confirmModalOptions,
          isLoading: false,
          isOpen: true,
          type: TypeAlertValues[TypeAlertValues.error],
          message:
            rehireEmployeeResponse.error.message ?? REHIRE_ERROR_MODAL_TITLE,
        });
        return;
      }
    } else {
      const worked = await UpdateStatusSapHiring({
        attempts,
        email: email,
        updateStatusSapHiring,
        qualifierName: splittedEmail[0],
        newModalOptions: async (value) => {
          setConfirmModalOptions(value);
          await refetch();
        },
      });
      if (!worked) {
        return;
      }
    }

    const worked = await updateStatusHiringFunction({
      attempts,
      email: email,
      updateStatusHiring,
      qualifierName: splittedEmail[0],
      newModalOptions: async (value) => {
        setConfirmModalOptions(value);
        await refetch();
      },
    });

    if (!worked) {
      return;
    }

    setConfirmModalOptions({
      ...confirmModalOptions,
      isOpen: true,
      message: SUCCESS_MODAL_TITLE,
      type: TypeAlertValues[TypeAlertValues.success],
      isLoading: false,
      confirmButton: true,
      cancelButton: false,
    });
    setTimeout(() => navigate(PREVIOUS_LOCATION), 2500);
  };

  return (
    <>
      {isLoading && <LoaderCataloge textContent="Cargando datos" />}
      <FormProvider {...form}>
        <HeaderAdmin />
        <ExternalCodesProvider>
          <div className={styles.AdminPanelForm}>
            <AdminPanelFormHeader
              onGoBack={onGoBack}
              handleConfirm={handleConfirm}
              isFormValid={isFormValid}
              email={email}
            />
            <NotSavedAlertModal modal={modal} setModal={setModal} />
            <ConfirmationModal
              confirmModalOptions={confirmModalOptions}
              setConfirmModalOptions={setConfirmModalOptions}
            />
            <VerticalTabPanel className={styles.AdminPanelForm__Panel}>
              <VerticalTabPanel.TabList>
                <VerticalTabPanel.Tab
                  id={0}
                  onClick={() => handleTabClick(0)}
                  variant={isTabValid(0)}
                >
                  Información personal
                </VerticalTabPanel.Tab>
                <VerticalTabPanel.Tab
                  id={1}
                  onClick={() => handleTabClick(1)}
                  variant={isTabValid(1)}
                >
                  Información de contacto
                </VerticalTabPanel.Tab>
                <VerticalTabPanel.Tab
                  id={2}
                  onClick={() => handleTabClick(2)}
                  variant={isTabValid(2)}
                >
                  Información laboral y de compensación
                </VerticalTabPanel.Tab>
                <VerticalTabPanel.Tab
                  id={3}
                  onClick={() => handleTabClick(3)}
                  variant={isTabValid(3)}
                >
                  Información organizacional
                </VerticalTabPanel.Tab>
                <VerticalTabPanel.Tab
                  id={4}
                  onClick={() => handleTabClick(4)}
                  variant={isTabValid(4)}
                >
                  Información academica
                </VerticalTabPanel.Tab>
              </VerticalTabPanel.TabList>
              <VerticalTabPanel.Panel id={0}>
                {!isLoading && <PersonalDataSection />}
              </VerticalTabPanel.Panel>
              <VerticalTabPanel.Panel id={1}>
                <ContactDataSection />
              </VerticalTabPanel.Panel>
              <VerticalTabPanel.Panel id={2}>
                <EmploymentSection />
              </VerticalTabPanel.Panel>
              <VerticalTabPanel.Panel id={3}>
                <OrganizationSection />
              </VerticalTabPanel.Panel>
              <VerticalTabPanel.Panel id={4}>
                <AcademicDataSection />
              </VerticalTabPanel.Panel>
            </VerticalTabPanel>
          </div>
        </ExternalCodesProvider>
        <div className="footer-admin-container">
          <Footer isDark/>
        </div>
      </FormProvider>
    </>
  );
}

function AdminPanelFormHeader({
  onGoBack,
  handleConfirm,
  isFormValid,
  email,
}: Readonly<{
  onGoBack: () => void;
  handleConfirm: () => void;
  isFormValid: boolean;
  email?: string;
}>) {
  return (
    <div className={styles.AdminPanelForm__Header}>
      <div>
        <button
          className={styles.AdminPanelForm__BackButton}
          onClick={onGoBack}
        >
          <img alt="Back arrow" src={arrowLeftPanel} />
        </button>
      </div>
      <div className={styles.AdminPanelForm__HeaderContainer}>
        <h2 className={styles.AdminPanelForm__Title}>Información</h2>
        <p className={styles.AdminPanelForm__Description}>
          Recuerda completar toda la información obligatoria para completar el
          proceso del candidato{' '}
          <span className={styles.AdminPanelForm__Email}>
            {email ?? 'example@example.com'}
          </span>
        </p>
      </div>
      <div>
        <AButton
          disabled={!isFormValid}
          className="button-primary"
          handleClick={handleConfirm}
          tag="button"
        >
          Confirmar
        </AButton>
      </div>
    </div>
  );
}

function NotSavedAlertModal({
  modal,
  setModal,
}: Readonly<{
  modal: boolean;
  setModal: (value: boolean) => void;
}>) {
  function handleExit() {
    window.location.reload();
  }

  return (
    <Modal isVisible={modal}>
      <AlertModalCard
        description={ALERT_MODAL_DESCRIPTION}
        iconBackground="Yellow"
        Icon={
          <WarningIcon
            style={{ width: '40px', height: '40px', filter: 'inherit' }}
          />
        }
      >
        <div className={styles.containerButtonsModal}>
          <button className={styles.secondary} onClick={() => setModal(false)}>
            Seguir editando
          </button>
          <button className={styles.primary} onClick={handleExit}>
            Salir
          </button>
        </div>
      </AlertModalCard>
    </Modal>
  );
}

function ConfirmationModal({
  confirmModalOptions,
  setConfirmModalOptions,
}: Readonly<{
  confirmModalOptions: ModalOptions;
  setConfirmModalOptions: (value: ModalOptions) => void;
}>) {
  return (
    <Modal isVisible={confirmModalOptions.isOpen}>
      <main className={styles.modalCard}>
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.error] && (
            <AButton
              tag="button"
              className={styles.modalCard__closeButton}
              handleClick={() =>
                setConfirmModalOptions({
                  ...confirmModalOptions,
                  isOpen: false,
                })
              }
            >
              {' '}
              ×
            </AButton>
          )}
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.inProgress] && (
            <Loader otherClassName={styles.modalCard__loader} />
          )}
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.error] && (
            <img
              alt="Error icon"
              src={iconError}
              className={styles.modalCard__icon}
            />
          )}
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.success] && (
            <img
              alt="Success icon"
              src={iconSuccess}
              className={styles.modalCard__icon}
            />
          )}

        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.success] && (
            <p className={styles.modalCard__title}> Super </p>
          )}
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.error] && (
            <p className={styles.modalCard__title}> Ups </p>
          )}
        {confirmModalOptions.type ===
          TypeAlertValues[TypeAlertValues.inProgress] && (
            <p className={styles.modalCard__title}>
              {' '}
              {confirmModalOptions.message}{' '}
            </p>
          )}

        {confirmModalOptions.type !==
          TypeAlertValues[TypeAlertValues.inProgress] && (
            <p className={styles.modalCard__message}>
              {confirmModalOptions.message}
            </p>
          )}
      </main>
    </Modal>
  );
}
