import { IResponseMaintenance } from "utils/interfaces";
import { talentoApi } from "./index";

const maintenanceExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    getMaintenanceFromAdmin: builder.query<IResponseMaintenance, any>({
      query() {
        return {
          url: `/admin/parameter/maintenance`,
        };
      },
    }),
    getMaintenanceFromStage: builder.query<IResponseMaintenance, string>({
      query(id) {
        return {
          url: `/stages/parameter/id/${id}`,
        };
      },
    }),
  }),
});

export const {
  useGetMaintenanceFromAdminQuery,
  useGetMaintenanceFromStageQuery,
} = maintenanceExtensionApi;