import ModalTriangle from "components/atoms/ModalTriangle/ModalTriangle.component";
import "./InfoTooltip.scss"
import React from "react";

interface IInfoTooltip extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  text: React.ReactNode;
}

const InfoTooltip = ({ title, text, ...props }: IInfoTooltip) => {
  return (
    <div className="info-tooltip" {...props}>
      <ModalTriangle trianglePosition="left" />
      <h3 className="info-tooltip__title">{title}</h3>
      <p className="info-tooltip__text">{text}</p>
    </div>
  );
}

export default InfoTooltip;