import VerticalTabPanel from 'components/molecules/VerticalTabPanel';
import styles from './completedAdminForm.module.scss';
import HeaderAdmin from '../AdminPanelForm/HeaderAdmin.component';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetInfoCandidateQuery } from 'services/talento';
import { IAdminPanelForm } from '../AdminPanelForm/AdminPanelForm.schema';
import { LoaderCataloge } from 'components/atoms/LoaderCataloge/LoaderCataloge.components';
import { arrowLeftPanel } from 'utils/assets';
import { Numbers, panel } from 'utils/helpers/dictionaries';
import { LOADING_DATA } from 'utils/constants/UploadDocumentsInternship/indexConstants';
export const CompletedAdminForm = () => {
  const { email } = useParams();
  const { data, isLoading } = useGetInfoCandidateQuery<{
    data?: { data: IAdminPanelForm };
    isLoading: boolean;
  }>({ email });

  const navigate = useNavigate();
  const PREVIOUS_LOCATION = -Numbers.one;
  const onGoBack = () => {
    navigate(PREVIOUS_LOCATION);
  };
  return (
    <>
      {isLoading && <LoaderCataloge textContent={LOADING_DATA} />}
      <section className={styles.PageWrapper}>
        <HeaderAdmin />
        <div className={styles.AdminPanelForm}>
          <div className={styles.AdminPanelForm__Header}>
            <div>
              <button
                className={styles.AdminPanelForm__BackButton}
                onClick={onGoBack}
              >
                <img src={arrowLeftPanel} />
              </button>
            </div>
            <div className={styles.AdminPanelForm__HeaderContainer}>
              <h2 className={styles.AdminPanelForm__Title}>Información</h2>
              <p className={styles.AdminPanelForm__Description}>
                Recuerda completar toda la información obligatoria para
                completar el proceso del candidato{' '}
                <span className={styles.AdminPanelForm__Email}>
                  {email ?? 'example@example.com'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <VerticalTabPanel className={styles.PageWrapper__Panel}>
          <VerticalTabPanel.TabList>
            <VerticalTabPanel.Tab id={Numbers.zero}>
              {panel.personalInformationTitle}
            </VerticalTabPanel.Tab>
            <VerticalTabPanel.Tab id={Numbers.one}>
              {panel.contactInformationTitle}
            </VerticalTabPanel.Tab>
            <VerticalTabPanel.Tab id={Numbers.two}>
              {panel.compesationInformationTitle}
            </VerticalTabPanel.Tab>
            <VerticalTabPanel.Tab id={Numbers.three}>
              {panel.organizationalInformationTitle}
            </VerticalTabPanel.Tab>
            <VerticalTabPanel.Tab id={Numbers.four}>
              {panel.academicInformationTitle}
            </VerticalTabPanel.Tab>
          </VerticalTabPanel.TabList>
          <VerticalTabPanel.Panel id={Numbers.zero}>
            <section className={styles.PageWrapper__Panel__DataSection}>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.personalInformationTitle}
              </h2>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.firstNamePanelCompleted}: {data?.data?.names}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.secondNamePanelCompleted} {data?.data?.secondName}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.firstLastNamePanelCompleted} {data?.data?.lastname}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.secondLastNamePanelCompleted}  {data?.data?.secondLastname}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.birthdatePanelCompleted} {data?.data?.birthdate}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.genderPanelCompleted} {data?.data.gender}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.civilStatusPanelCompleted} {data?.data?.civilStatus}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.documentTypePanelCompleted} {data?.data?.documentType}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.documentNumberPanelCompleted} {data?.data?.documentNumber}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.bloodGroupPanelCompleted} {data?.data?.bloodGroup}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.preferredNamePanelCompleted} {data?.data?.preferredName}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.shirtSizePanelCompleted} {data?.data?.shirtSize}
              </p>
            </section>
          </VerticalTabPanel.Panel>
          <VerticalTabPanel.Panel id={Numbers.one}>
            <section className={styles.PageWrapper__Panel__DataSection}>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.contactInformationTitle}
              </h2>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.countryOfResidencePanelCompleted} {data?.data?.countryOfResidence}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.departamentOfResidencePanelCompleted} {data?.data?.departamentOfResidence}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.cityOfResidencePanelCompleted} {data?.data?.cityOfResidence}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.typeDirectionPanel}  {data?.data?.addressType}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.directionPanelCompleted} {data?.data?.address}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                 {panel.directionComplementedPanelCompleted} {data?.data?.complementaryAddresses}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.contactNumberPanelCompleted} {data?.data?.phone}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.emailPanelCompleted} {data?.data?.email}
              </p>
            </section>
          </VerticalTabPanel.Panel>
          <VerticalTabPanel.Panel id={Numbers.two}>
            <section className={styles.PageWrapper__Panel__DataSection}>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.compesationInformationTitle}
              </h2>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.contractTypePanelCompleted}{' '}
                {data?.data?.organizationalInformation.contractType}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.positionPanelCompleted} {data?.data?.organizationalInformation.position}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.startDatePanelCompleted}{' '}
                {data?.data?.organizationalInformation.startDate}
              </p>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.compensationInformationTitlePanelCompleted}
              </h2>
              {data?.data.compensationInformation.map((compensation) => (
                <div key={compensation.id}>
                  <p
                    className={styles.PageWrapper__Panel__DataSection__element}
                  >
                    {panel.nominaPanelCompleted} {compensation.payrollComponent}
                  </p>
                  <p
                    className={styles.PageWrapper__Panel__DataSection__element}
                  >
                    {panel.salaryBasePanelCompleted} {compensation.salaryBase}
                  </p>
                </div>
              ))}
            </section>
          </VerticalTabPanel.Panel>
          <VerticalTabPanel.Panel id={Numbers.three}>
            <section className={styles.PageWrapper__Panel__DataSection}>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.organizationalInformationTitle}
              </h2>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
               {panel.divisionPanelCompleted} {data?.data?.organizationalInformation.division}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
               {panel.departmentPanelCompleted} {data?.data?.organizationalInformation.department}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.chapterOnePanelCompleted}{' '}
                {data?.data?.growInformation[Numbers.zero].chapter}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.chapterTwoPanelCompleted}{' '}
                {data?.data?.growInformation[Numbers.one]?.chapter}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.technologyOnePanelCompleted}
                {data?.data?.growInformation[Numbers.zero].technology}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.technologyTwoPanelCompleted}
                {data?.data?.growInformation[Numbers.one]?.technology}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.technicalLevelOnePanelCompleted}
                {data?.data?.growInformation[Numbers.zero].technicaLevel}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
                {panel.technicalLevelTwoPanelCompleted}
                {data?.data?.growInformation[Numbers.one]?.technicaLevel}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.referredByPanelCompleted} {data?.data?.organizationalInformation.referred}
              </p>
            </section>
          </VerticalTabPanel.Panel>
          <VerticalTabPanel.Panel id={Numbers.four}>
            <section className={styles.PageWrapper__Panel__DataSection}>
              <h2 className={styles.PageWrapper__Panel__DataSection__title}>
                {panel.academicInformationTitle}
              </h2>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.professionalTitlePanelCompleted} {data?.data?.professionalTitle}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.professionalCardNumberPanelCompleted} {data?.data?.professionalCard}
              </p>
              <p className={styles.PageWrapper__Panel__DataSection__element}>
              {panel.cvPanelCompleted} {data?.data?.cv}
              </p>
            </section>
          </VerticalTabPanel.Panel>
        </VerticalTabPanel>
      </section>
    </>
  );
};
