import { useState } from 'react';
import { selectTranslations } from 'store/translation/translation.slice';
import { TTabStage } from 'utils/interfaces/store/user.interfaces';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';
import { Tab } from 'components/atoms/Tab/Tab.components';
import { StagesList } from 'components/organisms';
import './StagesHome.scss';
import '../StagesList/StagesList.scss';
import { selectOnboardingDashboard } from 'store';
import OnboardingModal from 'components/molecules/OnboardingModal/OnboardingModal.component';
import { ONBOARDING_MODALS } from 'utils/constants/Onboarding/indexOnboarding';
import { Numbers } from 'utils/helpers/dictionaries';

interface IProps {
  disabled?: boolean;
}

export const StagesHome = ({ disabled }: IProps) => {
  const [currentTab, setCurrentTab] = useState<TTabStage>('active');
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const { showOnboarding, step } = useAppSelector(selectOnboardingDashboard);

  return (
    <div className={`stagesHome ${step === 3 || step === 4 ? "stagesHome__overflow" : ""}`}>
      <section className={`stagesHome__tabs ${step === 3 && "stagesHome__tabs--active"}`}>
        <Tab
          name={t('currentStages')}
          to="active"
          isActive={currentTab === 'active'}
          setCurrentTab={setCurrentTab}
          disabled={disabled}
        />
        <Tab
          name={t('completedStages')}
          to="completed"
          isActive={currentTab === 'completed'}
          setCurrentTab={setCurrentTab}
          disabled={disabled}
        />
        {showOnboarding && step === 3 && (
          <OnboardingModal
            title={ONBOARDING_MODALS[3].TITLE}
            text={ONBOARDING_MODALS[3].TEXT}
            step={Numbers.three}
            className={ONBOARDING_MODALS[3].CLASSNAME}
            trianglePosition={ONBOARDING_MODALS[3].TRIANGLE_POSITION}
            bold={ONBOARDING_MODALS[3].BOLD}
          />
        )}
      </section>

      {disabled ? (
        <StagesList currentTab={currentTab} disabled />
      ) : (
        <StagesList currentTab={currentTab} />
      )}
    </div>
  );
};
