import { Auth } from 'aws-amplify';
import { logout as logoutCleanState } from 'store';
import { useAppDispatch } from 'store/hooks';
import { TabbarItem, AImage, AButton } from 'components/atoms';
import './SideMenu.scss';
import {
  userIcon,
  logoutIcon,
  hambuergerIcon,
  pragmaCircleLogo,
} from 'utils/assets';
import { useState } from 'react';
import { TabbarItemPath, tabbarItems } from 'utils/constants';

interface IProps {
  hiddenItems?: TabbarItemPath[];
}

export const SideMenu = ({ hiddenItems }: IProps) => {
  const dispatch = useAppDispatch();

  const [menuActive, setMenuActive] = useState(false);

  const handleClick = async () => {
    await Auth.signOut();
    dispatch(logoutCleanState());
  };

  if (!menuActive) {
    return (
      <AImage
        handleClick={() => setMenuActive(!menuActive)}
        className="navigation-button"
        url={hambuergerIcon}
        alt="Icono desplegar menu"
        dataTestid="navigation-button"
      ></AImage>
    );
  }

  return (
    <div className="navigation-wrap">
      <div className="navigation" id="navigation">
        <div className="navigation-menu">
          <AImage
            className="navigation-menu__image"
            url={pragmaCircleLogo}
            alt=""
          />
          <p className="navigation-menu__text">
            Ingreso
            <span className="navigation-menu__text--modifier">Pragma</span>
          </p>
        </div>
        <div className="navigation-menu--tabs">
          {tabbarItems.map(({ title, path, icon, isDisabled }) => {
            if (hiddenItems && hiddenItems.includes(path)) return <></>;

            return (
              <li key={path}>
                <TabbarItem
                  path={path}
                  title={title}
                  icon={icon}
                  isDisabled={isDisabled}
                  enableText={menuActive}
                />
              </li>
            );
          })}
        </div>
        <div className="tabbar__logout">
        </div>
      </div>
      <div
        className="navigation--bg"
        onClick={() => setMenuActive(!menuActive)}
      ></div>
    </div>
  );
};
