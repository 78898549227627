import { yupResolver } from '@hookform/resolvers/yup';
import { AButton, AImage } from 'components/atoms';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { selectHelpCommentForm } from 'store';
import { dataOptionsHelp, IModal } from 'utils/interfaces';
import { IHelpCommentForm } from 'utils/interfaces/forms/helpCommentForm.interfaces';
import { helpCommentFormSchema } from 'utils/schemas';
import { ModalAlert } from '../ModalAlert/ModalAlert.components';
import './HelpComment.scss';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo } from 'store';
import ImageUpload from 'components/organisms/ImageUpload/ImageUpload.component';
import { IFiles, IFileData } from 'utils/interfaces';
import { useGetStagesQuery, useSendImageMutation } from 'services/talento';
import { t } from 'i18next';
import { ImageUploadConstants } from 'utils/constants/constants';
import { read } from 'fs';
import { useNavigate } from 'react-router-dom';
import { iconBack } from 'utils/assets';
interface IProps {
  data: dataOptionsHelp;
  setStateHelp: Dispatch<SetStateAction<string>>;
  idValue: number;
}

export const HelpComment: React.FC<IProps> = ({
  data,
  setStateHelp,
  idValue,
}: IProps) => {
  const { textArea } = useAppSelector(selectHelpCommentForm);
  const { email } = useAppSelector(selectUserInfo);
  const { refetch: getStages, status } = useGetStagesQuery({ email });
  const [filesDone, setFilesDone] = useState(false);
  const [sendFileDone, setSendFileDone] = useState(false);
  const [files, setFiles] = useState<IFiles[]>([]);
  const [filesFromImageUpload, setFilesFromImageUpload] = useState<IFiles[]>(
    []
  );
  const navigate = useNavigate();
  const [sendImage] = useSendImageMutation();
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    handleClick: () => {},
  });

  const successId = ImageUploadConstants.SUCCESS_SUPPORT_ID;
  const successMessage = ImageUploadConstants.SUPPORT_MESSAGE.SUCCESS;
  const defaultMessage = ImageUploadConstants.SUPPORT_MESSAGE.DEFAULT;
  const statusCodeOk = ImageUploadConstants.HTTP_STATUS_CODES.OK;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<IHelpCommentForm>({
    mode: 'onChange',
    defaultValues: { textArea },
    resolver: yupResolver(helpCommentFormSchema),
  });

  const convertFileToBase64 = () => {
    const readFileAsDataURL = (file: any): Promise<String> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          if (reader.result) resolve(reader.result.toString().split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    Promise.all(
      filesFromImageUpload.map(async (element) => {
        try {
          let base64: String = await readFileAsDataURL(element.file);
          element.base64 = base64.toString();
          return element;
        } catch (error) {
          console.error('Error al convertir el archivo a base64:', error);
          return element;
        }
      })
    ).then((updatedFiles) => {
      setFiles(updatedFiles);
      setFilesDone(true);
    });
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (value) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleStatus = () => {
    const message = idValue == successId ? successMessage : defaultMessage;
    setModal({
      message: message,
      type: 'send',
      isOpen: true,
    });
  };
  const handleUploadFiles = () => {
    const listFiles: IFileData[] = files.map((item) => ({
      nombre: item.nameFile,
      base64: item.base64,
    }));

    const values = getValues();

    sendImage({
      id: idValue,
      email: email,
      option: data.option,
      message: values.textArea,
      adjuntos: listFiles,
    })
      .then(({ data }: any) => {
        if (data.statusCode === statusCodeOk) {
          getStages();
        }
      })
      .catch((e) => {
        setModal({
          message: t('failedConnectServer'),
          type: 'error',
          isOpen: true,
          closeIcon: true,
        });
      })
      .finally(() => setSendFileDone(true));
  };

  useEffect(() => {
    filesDone && handleUploadFiles();
  }, [filesDone]);

  return (
    <>
      <ModalAlert
        data-testid="modal"
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        confirmButton={true}
        toggleModal={(isOpen: boolean) =>
          setModal((state) => ({ ...state, isOpen }))
        }
        handleClick={() => setStateHelp('selectOption')}
      />

      <div className="main__comment" data-testid="main__container">
        <h1 className="main__comment__title">
          <span
            className="button-container"
            onClick={() => {
              setStateHelp('selectOption');
            }}
          >
            <img className="button-container-img" src={iconBack} data-testid="back-button-img" />
          </span>
          <span className="title-text">{JSON.parse(data.action).title}</span>
        </h1>
        <p className="main__comment__subtitle-comment">
          {JSON.parse(data.action).description}
        </p>

        <form
          className="main__comment__form"
          onSubmit={handleSubmit(handleStatus)}
        >
          <div className="main__comment__textarea">
            <textarea
              id="textarea"
              required
              placeholder="Message"
              className={`input__textarea ${
                errors.textArea && 'input__textarea--error'
              }`}
              {...register('textArea', { onChange: handleChange })}
            ></textarea>
            <p
              className={`input__textarea-characters ${
                errors.textArea && ' input__textarea-characters--error'
              }`}
            >
              Máximo 500 caracteres
            </p>
          </div>
          <br></br>
          {data.id !== 5 && (
            <div>
              <ImageUpload
                title="Cargar imagen"
                onFilesChange={setFilesFromImageUpload}
              />
            </div>
          )}
          <article
            className="container__button-comment"
            data-testid="button__container"
          >
            <AButton
              type="submit"
              tag="button"
              disabled={isDisabled || !!errors.textArea}
              className={'button-primary--dark button-comment'}
              handleClick={convertFileToBase64}
            >
              {JSON.parse(data.action).buttonSend}
            </AButton>
          </article>
        </form>
      </div>
    </>
  );
};
