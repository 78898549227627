import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICashFlow,
  ISalaryOption,
} from 'components/organisms/SalaryOptions/types';
import {
  GetSalaryMultipleOptionsType,
} from 'components/organisms/SalaryOptions/api/salaryOptionsAPI';
import { RootState } from 'store/store';

interface ISalaryOptionsState {
  salaryMultipleOptions: ISalaryOption[] | null;
  isSalaryMultipleOptionError: boolean;
  isLoadingSalaryMultipleOptions: boolean;
  selectedOption: ISalaryOption | null;
  salaryCashFlow: ICashFlow[] | null;
}

interface ISalaryMultipleOptionsPayload {
  salary: number;
  optionObservations: string[];
  getSalaryMultipleOptions: GetSalaryMultipleOptionsType;
}

const initialState: ISalaryOptionsState = {
  salaryMultipleOptions: null,
  isLoadingSalaryMultipleOptions: false,
  isSalaryMultipleOptionError: false,
  selectedOption: null,
  salaryCashFlow: null,
};

export const fetchSalaryMultipleOptions = createAsyncThunk(
  'salaryOptions/fetchSalaryMultipleOptions',
  async ({
    salary,
    optionObservations,
    getSalaryMultipleOptions,
  }: ISalaryMultipleOptionsPayload) => {
    try {
      const response = await getSalaryMultipleOptions({
        salary,
      }).unwrap();
      const options = response?.salaryoptions.map((option, index) => ({
        ...option,
        observations: optionObservations[index],
      })) as ISalaryOption[];
      return options;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  }
);

const salaryOptionsSlice = createSlice({
  name: 'salaryOptions',
  initialState,
  reducers: {
    setSalaryMultipleOptions(state, action: PayloadAction<ISalaryOption[]>) {
      state.salaryMultipleOptions = action.payload;
    },
    setSelectedOption(state, action: PayloadAction<ISalaryOption>) {
      state.selectedOption = action.payload;
    },
    setSalaryCashFlow(state, action: PayloadAction<ICashFlow[]>) {
      const existingCashFlow = state.salaryCashFlow?.findIndex(
        (item) => item.name === action.payload[0].name
      );

      existingCashFlow !== -1
        ? (state.salaryCashFlow =
          state.salaryCashFlow?.map((item) =>
            item.name === action.payload[0].name ? action.payload[0] : item
          ) || [])
        : (state.salaryCashFlow = state.salaryCashFlow
          ? [...state.salaryCashFlow, ...action.payload]
          : action.payload);
    },
    setSalaryMultipleOptionError(state, action: PayloadAction<boolean>) {
      state.isSalaryMultipleOptionError = action.payload;
    },
    setLoadingSalaryMultipleOptions(state, action: PayloadAction<boolean>) {
      state.isLoadingSalaryMultipleOptions = action.payload;
    },
    updateSalaryOption(state, action: PayloadAction<ISalaryOption>) {
      if (state.salaryMultipleOptions) {
        const updatedOptions = state.salaryMultipleOptions.map((option) => {
          return option.name === action.payload.name ? action.payload : option
        });
        state.salaryMultipleOptions = updatedOptions;
      }
    },
    updateOptionCashFlow(state, action: PayloadAction<ISalaryOption>) {
      if (state.salaryMultipleOptions) {
        const updatedOptions = state.salaryMultipleOptions.map((option) => {
          return option.name === action.payload.name
            ? { ...option, anualCashFlow: action.payload.anualCashFlow }
            : option;
        });
        state.salaryMultipleOptions = updatedOptions;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalaryMultipleOptions.pending, (state) => {
      state.isLoadingSalaryMultipleOptions = true;
      state.isSalaryMultipleOptionError = false;
    });
    builder.addCase(fetchSalaryMultipleOptions.fulfilled, (state, action) => {
      state.salaryMultipleOptions = action.payload;
      state.isLoadingSalaryMultipleOptions = false;
    });
    builder.addCase(fetchSalaryMultipleOptions.rejected, (state, action) => {
      console.log(action.error);
      state.isSalaryMultipleOptionError = true;
      state.isLoadingSalaryMultipleOptions = false;
    });
  },
});

export const {
  setSalaryMultipleOptions,
  setSelectedOption,
  setSalaryCashFlow,
  setSalaryMultipleOptionError,
  setLoadingSalaryMultipleOptions,
  updateSalaryOption,
  updateOptionCashFlow,
} = salaryOptionsSlice.actions;

export default salaryOptionsSlice.reducer;

export const selectSalaryMultipleOptions = (state: RootState) =>
  state.salaryOptions.salaryMultipleOptions;
