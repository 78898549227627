import { talentoApi } from "./index";

interface INotification {
    id: number;
    notificationId: number;
    title: string;
    description: string;
    path: string;
    notificationStatus: string;
    notificationType?: string
}


const notificationsExtensionApi = talentoApi.injectEndpoints({
    endpoints: (builder) => ({
        getListNotifications: builder.query<{ data: INotification[] }, { email: string }>({
            query({ email }) {
                return {
                    url: `/notification/real-time/${email}`,
                    method: 'GET',
                };
            },
        }),
        deleteNotificationsFirebase: builder.mutation({
            query: (email) => ({
                url: `/notification/real-time/${email}`,
                method: 'DELETE'
            }),
        }),
        updateStatusNotifications: builder.mutation({
            query: ({ idNotificacion, status }) => ({
                url: `/notification/real-time/${idNotificacion}`,
                method: 'PATCH',
                body: { status },
            }),
        }),
        getApplicationStatus: builder.query<any, { email: string }>({
            query: ({ email }) => {
                return {
                    url: `/notification/get-notification-status/email?email=${email}`,
                };
            },
        }),
        getApplicationStatusbusinessDayATR: builder.query<any, { email: string }>({
            query: ({ email }) => {
                return {
                    url: `/notification/get-notification-status/businessDayATR/email?email=${email}`,
                };
            },
        }),
    }),
});

export const {
    useGetListNotificationsQuery,
    useDeleteNotificationsFirebaseMutation,
    useUpdateStatusNotificationsMutation, 
    useGetApplicationStatusQuery,
    useGetApplicationStatusbusinessDayATRQuery
} = notificationsExtensionApi;